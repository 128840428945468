<template>
  <b-container fluid class="p-0">
    <filter-slot
      class="border-top-primary border-3 border-table-radius p-0 m-0"
      :filter="allfilters"
      :totalRows="totalRows"
      :paginate="paginate"
      :startPage="startPage"
      :toPage="toPage"
      :filterPrincipal="filters.filterPrincipal"
      @reload="$refs['disputeResultsList'].refresh()"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reset-all-filters="refreshTable"
    >
      <template #table>
        <b-table
          class="m-0 p-0 w-100"
          ref="disputeResultsList"
          :items="dataProvider"
          :fields="deletedFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          :sort-direction="order"
          @sort-changed="changeStatusOrder"
          show-empty
          :sticky-header="'70vh'"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(lead_name)="data">
            <span class="d-flex align-items-center" style="gap: 4px">
              <router-link
                v-if="moduleId != 22"
                :to="{
                  name:
                    moduleId == 5
                      ? 'debt-solution-dashboard'
                      : moduleId == 7
                      ? 'boost-credit-dashboard'
                      : moduleId == 6
                      ? 'credit-experts-dashboard'
                      : moduleId == 20
                      ? 'connection-clients-account'
                      : moduleId == 28
                      ? 'specialists-client-dashboard'
                      : null,
                  params: { idClient: `${data.item.account_id}` },
                }"
                target="_blank"
              >
                {{ data.item.client_name }}
              </router-link>
              <b-badge
                class="font10 ml-1"
                :variant="
                  data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
                "
                v-if="[6, 20, 21, 22].includes(data.item.module_id)"
              >
                {{ data.item.name_type_client }}
              </b-badge>
              <router-link
                v-if="
                  moduleId == 22 &&
                  (currentUser.role_id === 1 ||
                    currentUser.role_id === 17 ||
                    currentUser.role_id === 2 ||
                    currentUser.role_id === 14)
                "
                :to="{
                  name: 'ce-customer-service-dashboard',
                  params: {
                    idClient: data.item.account_id,
                    hasAccess: true, // is alert
                    typeAccess: 6, // Welcome,
                    wayAccess: 4, // enter by modal
                  },
                }"
              >
                <icon-status-account :status="data.item.status_account" />
                {{ data.item.client_name }}
              </router-link>
            </span>
            <status-account :account="data.item" :text="true" />
            <div class="d-flex align-items-center">
              <span class="d-block">
                {{ data.item.client_account }}
              </span>
            </div>
          </template>
          <template #cell(program)="data">
            <span class="text-center d-block">{{ data.item.program }}</span>
          </template>

          <template #cell(title)="data">
            <div
              class="text-primary cursor-pointer font-weight-bolder"
              @click="openModalAddRound(data.item)"
            >
              {{
                [1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-"
              }}
            </div>
          </template>

          <template #cell(last_score_date)="data">
            {{ data.item.last_score_date | myGlobalDay }}
          </template>
          <template #cell(user_created)="data">
            <b-col>
              <b-row
                ><span> {{ data.item.user_created }} </span></b-row
              >
              <b-row
                ><span>{{
                  data.item.create_dispute | myGlobalWithHour
                }}</span></b-row
              >
            </b-col>
          </template>

          <template #cell(deleted_by)="data">
            {{ data.item.user_deleted }}
            <br />
            {{ data.item.deleted_at | myGlobalWithHour }}
          </template>

          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openUrModalTracking(item)"
                size="20"
                icon="ListIcon"
              />
            </div>
          </template>

          <template #cell(status)="data">
            <center>
              <b-badge
                pill
                :variant="
                  data.item.status === 'COMPLETED'
                    ? 'light-success'
                    : 'light-warning'
                "
                :class="
                  data.item.status === 'COMPLETED'
                    ? 'text-success'
                    : 'text-warning'
                "
                class="w-75"
              >
                {{ data.item.status }}
              </b-badge>
            </center>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-request
      v-if="activeModalRequest"
      @closeMe="closeModalRequest"
      @refreshTable="refreshTable"
    />

    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
    <RequestUpdateTracking
      v-if="showUrModalTracking"
      :dataUr="dataUr"
      @close="showUrModalTracking = false"
    />
  </b-container>
</template>
<script>
//components
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalRequest from "./ModalRequest.vue";

// import ModalEditFirstRound from "@/views/commons/components/letters/components/modal/ModalEditFirstRound.vue";
//vuex
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
//data
import { inProcessFilters } from "../data/filters.data";
import { deletedFields } from "../data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import RequestUpdateTracking from "@/views/commons/components/dispute-results/views/components/modal/RequestUpdateTracking.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  created() {
    this.start();
  },
  components: {
    FilterSlot,
    ModalRequest,
    // ModalEditFirstRound,
    AddRoundModal,
    RequestUpdateTracking,
    StatusAccount,
  },
  data() {
    return {
      deletedFields: [],
      isBusy: false,
      filters: {},
      activeModalRequest: false,
      order: "desc",
      allfilters: DataFilters,
      modalEditState: false,
      itemClient: {},
      validateModal: 3,
      modalAddRoundLetterState: false,
      showUrModalTracking: false,
      dataUr: null,
    };
  },
  methods: {
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    async dataProvider() {
      await this.fillInProcessData({
        advisorid: null,
        from: this.getFilterByKey("from").model,
        name_text: this.filters.filterPrincipal.model,
        order: this.order,
        orderby: "deleted_at",
        program: this.gProgram(this.moduleId).value,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        roleid: this.currentUser.role_id,
        status: 1,
        to: this.getFilterByKey("to").model,
        userid: this.currentUser.user_id,
        typee: this.getFilterByKey("status").model,
        f_specialist: this.getFilterByKey("specialist").model,
        is_deleted: 1,
        is_digital: this.isDigital,
      });
      return this.gInProcessItems;
    },
    getFilterByKey(key) {
      return this.allfilters.find((filter) => filter.key === `${key}`);
    },
    async getSpecialist() {
      let result = [];
      const params = {
        module_id: 11,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      result = await UpdateService.getUsersProgramsspNcr(params);
      this.users = result.data;
      var newList = this.users.map(
        (s) => ({ label: "All", value: 0 }, { label: s.user_name, value: s.id })
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },
    start() {
      this.filters = inProcessFilters;
      this.deletedFields = [...deletedFields];
      this.getSpecialist();
    },
    refreshTable() {
      this.$refs.disputeResultsList.refresh();
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async openModalRequest(row) {
      this.addPreloader();
      this.M_SET_CLIENT({
        name: row.client_name,
        id: row.id,
        account: row.account,
        account_id: row.account_id,
        meEmail: row.id_credential,
      });
      this.M_SET_MODAL_DATA({
        request: row.type,
        observation: row.observation,
        recomendation: row.recomendation,
        userRecommendation: row.userrecommendation,
        createdRecommendation: row.created_recommendation,
        dateRecommendation: row.created_recommendation,
        comments: [
          row.commentary1,
          row.commentary2,
          row.commentary3,
          row.commentary4,
          row.commentary5,
        ],
      });
      await this.fillDataTableModal({ id: row.id });
      if (row.type === 2)
        await this.setEmailModal({ client_id: row.account_id });

      this.activeModalRequest = true;
    },
    closeModalRequest() {
      this.activeModalRequest = false;
    },
    ...mapMutations({
      M_SET_CLIENT: "DebtSolutionDisputeResult/M_SET_CLIENT",
      M_SET_MODAL_DATA: "DebtSolutionDisputeResult/M_SET_MODAL_DATA",
      M_CHANGE_STATUS_ORDER: "DebtSolutionDisputeResult/M_CHANGE_STATUS_ORDER",
    }),
    ...mapActions({
      fillInProcessData: "DebtSolutionDisputeResult/fillInProcessData",
      fillDataTableModal: "DebtSolutionDisputeResult/fillDataTableModal",
      setEmailModal: "DebtSolutionDisputeResult/setEmailModal",
    }),

    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: {
          id: data.letter_id,
          title: data.title,
          format: data.format,
        },
      };
      this.modalAddRoundLetterState = true;
    },
    openUrModalTracking(item) {
      this.showUrModalTracking = true;
      this.dataUr = item;
    },
  },
  computed: {
    isDigital() {
      return this.$route.meta.is_digital;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapState({
      totalRows: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.totalRows,
      paginate: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.paginate,
      startPage: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.startPage,
      toPage: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.toPage,
    }),
    ...mapGetters({
      gInProcessItems: "DebtSolutionDisputeResult/gInProcessItems",
      gProgram: "DebtSolutionDisputeResult/gProgram",
      currentUser: "auth/currentUser",
      totalRows: "DebtSolutionDisputeResult/gTotalRows",
      paginate: "DebtSolutionDisputeResult/gPaginate",
      startPage: "DebtSolutionDisputeResult/gStartPage",
      toPage: "DebtSolutionDisputeResult/gToPage",
      gOrder: "DebtSolutionDisputeResult/gOrder",
    }),
  },
};
</script>
