<template>
  <div>
    <TableUpdateRequest
      ref="tableUpdateRequest"
      :allfilters="DataFilters"
      :fields="visibleFields"
      :params="sendParams"
      order="desc"
    >
      <template #lead_name="data">
        <span class="d-flex align-items-center" style="gap: 4px">
          <router-link
            v-if="moduleId != 22"
            :to="accessElementTable.getToParams(data.item.account_id)"
            target="_blank"
          >
            {{ data.item.client_name }}
          </router-link>
          <b-badge
            class="font10 ml-1"
            :variant="
              data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
            "
            v-if="[6, 20, 21, 22].includes(data.item.module_id)"
          >
            {{ data.item.name_type_client }}
          </b-badge>
          <router-link
            v-if="getRouterLink(data.item.account_id).validModuleId"
            :to="getRouterLink(data.item.account_id).to"
          >
            <icon-status-account :status="data.item.status_account" />
            {{ data.item.client_name }}
          </router-link>
        </span>
        <status-account :account="data.item" :text="true" />
        <div class="d-flex align-items-center">
          <span class="d-block">
            {{ data.item.client_account }}
          </span>
        </div>
      </template>
      <template #title="data">
        <div
          class="text-primary cursor-pointer font-weight-bolder"
          @click="openModalAddRound(data.item)"
        >
          {{ [1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-" }}
        </div>
      </template>
      <template #last_score_date="data">
        {{ data.item.last_score_date | myGlobalDay }}
      </template>
      <template #user_created="data">
        <b-col>
          <b-row>
            <span> {{ data.item.user_created }} </span>
          </b-row>
          <b-row>
            <span>{{ data.item.create_dispute | myGlobalWithHour }} </span>
          </b-row>
        </b-col>
      </template>
      <template #deleted_by="data">
        {{ data.item.user_deleted }}
        <br />
        {{ data.item.deleted_at | myGlobalWithHour }}
      </template>
    </TableUpdateRequest>
  </div>
</template>
<script>
import { deletedFields } from "@/views/commons/components/dispute-results/views/data/fields.data";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import TableUpdateRequest from "@/views/commons/components/dispute-results/views/components/TableUpdateRequest.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import { mapGetters } from "vuex";

export default {
  name: "TableDeleted",
  components: {
    TableUpdateRequest,
    StatusAccount,
  },
  data() {
    return {
      deletedFields,
      DataFilters,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.deletedFields.map((item) => ({ ...item, visible: true }));
    },
    sendParams() {
      return {
        status: 1,
        orderby: "deleted_at",
      };
    },
    accessElementTable() {
      return this.$refs.tableUpdateRequest;
    },
  },
  methods: {
    getRouterLink(client_account_id) {
      return this.accessElementTable.getRouterLink(client_account_id);
    },
    getFilterByKey(key) {
      return this.DataFilters.find((filter) => filter.key === `${key}`);
    },
    async getSpecialist() {
      let result = [];
      const params = {
        module_id: 11,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      result = await UpdateService.getUsersProgramsspNcr(params);
      this.users = result.data;
      var newList = this.users.map(
        (s) => ({ label: "All", value: 0 }, { label: s.user_name, value: s.id })
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },
    start() {
      this.getSpecialist();
    },
  },
  mounted() {
    this.start();
  },
};
</script>
